import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av grid`}</h2>
    <p>{`Vi bruker et grid til å styre layouten på sidene våre. På små skjermer er gridet på 4 kolonner,
på mellomstore skjermer 6 kolonner og på store skjermer 12 kolonner.`}</p>
    <DSExample name="grid_Grid" mdxType="DSExample" />
    <p>{`Du kan la kolonner være bredere på små skjermer og smalere på mindre skjermer:`}</p>
    <DSExample name="grid_Grid_4col" mdxType="DSExample" />
    <p>{`En rad kan ha mange forskjellige bakgrunnsfarger:`}</p>
    <DSExample name="grid_GridRow_background" mdxType="DSExample" />
    <p>{`En kolonne kan også ha bakgrunnsfarger. De vil da være utfallende, slik at selve innholdet vil forholde seg til griden.`}</p>
    <DSExample name="grid_GridCol_background" mdxType="DSExample" />
    <h2>{`Grid`}</h2>
    <p>{`Gridet består av tre komponenter - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Grid />`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<GridRow />`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<GridCol />`}</code>{` - grid, rad og kolonne.`}</p>
    <p>{`Det kan være mange grids per side, men man kan ikke ha grids inni andre grids. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Grid`}</code>{`-komponenten brukes til å lage layout på side-nivå.`}</p>
    <p>{`Det finnes en rekke modifiers på alle tre komponentene som lar deg manipulere hvor innholdet skal plassere seg i en
kolonne. Ta en titt på prop-types for å finne disse.`}</p>
    <DSExample name="grid_Grid" mdxType="DSExample" />
    <p>{`Merk at designet for layout skal være basert på 4 kolonner på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{` skjermer, 6 på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{` og 12 på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lg`}</code>{` mens i APIet til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GridCol`}</code>{` sendes det inn
tall fra 0-12 for alle størrelsene. I praksis betyr det at man selv må konvertere fra 12 kolonner til riktig antall på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{`. For
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{` innebærer det at hver kolonne har bredde 3, slik at man må bruke multiplum av 3 for alle verdier som sendes inn til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cols`}</code>{` eller `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{`
propertyene. For `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{` har hver kolonne bredde 2 og man må bruke multiplum av 2.`}</p>
    <p>{`Riktig, oppgitte verdier er multiplum av 3 for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{` og 2 for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`6`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`3`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`md`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`4`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Galt:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`3`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`md`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`5`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`4`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Det blir varslet om dette til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.error`}</code>{` i dev-versjonen av FFE, men det er ingen varsler om man bygger prod-versjonen av koden.
Selvom det fungerer å bruke andre verdier er det ikke meningen at man skal gjøre, og side-layout skal heller ikke være basert på at man
må gjøre det annerledes.`}</p>
    <h2>{`Spacing`}</h2>
    <p>{`Det er i utgangspunktet ingen luft over eller under gridet. Mellom kolonnene (og til høyre og venstre for griden) er det imidlertid et mellomrom på 16px. Disse verdiene kan overstyres med modifiere.`}</p>
    <DSExample name="grid_Grid_spacing" mdxType="DSExample" />
    <h3>{`Margin og padding`}</h3>
    <p>{`Margin og padding kan legges til i topp og bunn av en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<GridRow>`}</code>{` med modifierne `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{`. Tillatte verdier samsvarer med `}<a parentName="p" {...{
        "href": "https://design.sparebank1.no/profil/spacing/"
      }}>{`felles variabler for spacing i ffe`}</a>{`. Eksempel med 64px margin og 8px padding:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`3xl`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`padding`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`sm`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Sentrering`}</h3>
    <p>{`Innholdet i kolonner kan sentreres i begge akser ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`center`}</code>{`-propen, eller man kan sentrere tekst med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`centerText`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`centerText`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`center`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
            Sentrert kolonne
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Gap`}</h3>
    <p>{`Mellomrommet mellom kolonnene internt i et grid er 16px som default. Tilsvarende luft finnes til høyre og venstre for gridet. Dette kan overstyres ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gap`}</code>{`-modifieren på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Grid>`}</code>{`. Tillatte verdier samsvarer med `}<a parentName="p" {...{
        "href": "https://design.sparebank1.no/profil/spacing/"
      }}>{`felles variabler for spacing i ffe`}</a>{`, men kan ikke være større enn 32px (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lg`}</code>{`). I tillegg kan spacingen fjernes helt med verdien `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`none`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`gap`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`none`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Responsiv spacing`}</h3>
    <p>{`Spacing kan også defineres responsivt ved hjelp av størrelsesmodifierne `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lg`}</code>{`. Dette gjør det mulig å ha ulik spacing på ulike skjermstørrelser. Props som kan endres på denne måten er `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gap`}</code>{` på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Grid>`}</code>{`-elementet, samt `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<GridRow>`}</code>{`. Tillatte verdier er tilsvarende som for de ikke-responsive variantene av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gap`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{`.`}</p>
    <p>{`Et eksempel på responsiv spacing på et grid med progressiv økning av gap og margin/padding kan se slik ut:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`gap`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'sm'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`md`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`gap`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'md'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`lg`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`gap`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'lg'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'sm'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'sm'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`md`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'md'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'md'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`lg`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'lg'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'lg'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
            ...
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`De responsive og ikke-responsive spacing-verdiene kan også kombineres. Responsive verdier overstyrer de ikke-responsive, som betyr at de ikke-responsive verdiene i praksis er default.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`xs`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'sm'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`md`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'md'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`lg`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`margin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'lg'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <h2>{`Offset`}</h2>
    <p>{`Kolonner kan gis innrykk ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{`-propertyen. I praksis fungerer dette slik at gridet hopper over X antall kolonner før innholdet vises. Dette betyr blant annet at man kan sentrere grids med innhold som strekker seg over færre enn 12 kolonner.`}</p>
    <p>{`Propertyen settes på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<GridCol>`}</code>{` i første kolonne i gridet som skal vises med innrykk. Verdien tilsvarer antall kolonner som skal hoppes over. Den må defineres for hver skjermstørrelse, samtidig som man definerer kolonnens bredde. Større skjermstørrelser arver verdien fra mindre. For eksempel vil offset som defineres i skjermstørrelse `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sm`}</code>{` også gjelde for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`md`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lg`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`6`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`2`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`innhold`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridRow`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Grid`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`For å sentrere gridets innhold må man passe på at totalen av (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` x 2) og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cols`}</code>{` går opp i 12. For eksempel kan innhold over 8 kolonner løses slik:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`sm`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`cols`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`8`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`offset`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`2`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`innhold`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`GridCol`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`De to siste kolonnene i gridets totale bredde på 12 vil da være tomme, og på den måten skape lik spacing på begge sider.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`|----|----|----|----|----|----|----|----|----|----|----|----|
|    |    |xxxx|xxxx|xxxx|xxxx|xxxx|xxxx|xxxx|xxxx|    |    |
|----|----|----|----|----|----|----|----|----|----|----|----|`}</code></pre></div>
    <DSExample name="grid_Grid_offset" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      